<template>
    <div class="col-1-1 flex" :class="{'not-available': product.inStock === 2}">
        <div class="product flex h-center">
            <div v-if="product.inStock === 2" class="not-available">{{notAvailable}}</div>
            <div class="product-top col-4-12 sm-col-1-2 xs-col-1-1">
                <div class="product-image">
                    <img :src="product.imageUrl">
                </div>

                <h3 class="title">{{ product[`name${selectedLanguage}`] }}</h3>
            </div>
            

            <div class="product-right flex col-8-12 sm-col-1-2 xs-col-1-1">
                <div class="flex flex-column h-center product-info col-1-2 sm-col-1-1 ">
                    <div class="text-row" v-html="product[`description${selectedLanguage}`]"></div>
                    <div class="flex flex-column-reverse">
                        <div class="stamps-row" v-for="variant in product.variants" :key="variant.id">
                            <div class="stamps">{{ variant.stampAmount }} {{stamps}}</div>
                            <div class="price">
                                <span class="pricetag" :class="{ 'zero-stamps-price': variant.stampAmount === 0 }">{{ variant.price == 0.00 ? free : variant.price + ' &euro;' }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                
                <LoadingSpinner v-if="isLoading" />

                <template v-else>
                    <div v-if="product.inStock === 1" class="flex flex-column justify-center product-bottom col-1-2 sm-col-1-1">
                        <div v-if="productHasCoupon" class="coupon-code">
                            <div
                                class="form-row flex flex-column">
                                <label for="voucher">Coupon code *</label>
                                <input 
                                    type="text" 
                                    id="voucher"
                                    v-model.trim="couponCode"
                                    @input="$v.couponCode.$touch()"
                                    :class="{error: $v.couponCode.$error}" 
                                />
                                <div v-if="$v.couponCode.$dirty || showErrorMessage">
                                    <p class="error" v-if="!$v.couponCode.required">Coupon code {{isRequired}}</p>
                                </div>
                            </div>
                        </div>

                        <Counter 
                            :showLabel="true" 
                            @amountUpdated="updateAmount($event)" 
                            :quantity="amount" 
                            :maxQuantity="product.maxQuantity" 
                        />

                        <div class="buttons">
                            <button v-if="$store.state.account.authenticated" @click="navigateToEditOrder" class="btn btn-secondary">{{changeMyOrder}}</button>
                            <button v-else @click="orderProduct()" class="btn btn-secondary">{{orderProductButton}}</button>
                        </div>
                    </div>
                </template>    

                <div v-if="product.inStock === 3" class="flex flex-column justify-center product-bottom col-1-2 sm-col-1-1">
                    <p>{{outOfStock}}</p>
                    <div class="buttons">
                        <button @click="showNotifyModal()" class="btn btn-secondary">{{notify}}</button>
                    </div>

                    <NotificationModal
                        @closeModal="hideNotifyModal()"
                        v-show="showNotificationModal" 
                        :productId="product.id"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import Counter from '../components/ui/Counter.vue';
import NotificationModal from '../components/modals/NotificationModal.vue';
import LoadingSpinner from '../components/ui/LoadingSpinner.vue';
import {mapGetters} from 'vuex';
import agent from '../api/agent';

export default {
    name: 'ProductCatalogItem',
    components: { Counter, NotificationModal, LoadingSpinner },

    props: {
        product: Object,
        order: Object
    },

    validations: {couponCode: { required }},

    data() {
        return {
            amount: 0,
            couponCode: '',
            isLoading: false,
            showNotificationModal: false,
            showErrorMessage: false,
        }
    },

    created() {
        if (this.order) {
            this.order.lines.forEach(line => {
                if (line.name1 === this.product.name1) {
                    this.amount = line.quantity;
                }
            });
        }
    },

    computed: {
        ...mapGetters([
            'selectedLanguage', 
            // Settings
            'productHasCoupon',
            // Texts
            'isRequired',
            'orderProductButton',
            'stamps',
            'notAvailable',
            'outOfStock',
            'notify',
            'free',
            'changeMyOrder'
        ]),
    },

    methods: {
        updateAmount({amount}) {
            this.amount = amount;
        },

        createOrder() {
            if (this.order === null) {
                return { productId: this.product.id, quantity: this.amount, coupon: this.couponCode };
            } else {
                return { orderId: this.order.id, productId: this.product.id, quantity: this.amount, coupon: this.couponCode };
            }
        },  

        validateQuantity() {
            if (this.amount === 0) {
                this.$emit('showModal', true);
            } else {
                this.saveOrder();
            }     
        },

        async saveOrder() {
            this.isLoading = true;
            try {
                const id = await agent.order.saveProduct(this.createOrder());
                this.isLoading = false;
                this.$router.push(`/place-reservation/summary?orderId=${id}`);
            } catch (error) {
                console.error(error);
                this.isLoading = false;
            }
        },

        orderProduct() {
            if (this.productHasCoupon) {
                if (this.$v.$invalid) {
                   this.showErrorMessage = true;
                }
            }
            this.validateQuantity();
        },

        showNotifyModal() {
            this.showNotificationModal = true;
        },

        hideNotifyModal() {
            this.showNotificationModal = false;
        },

        parseJwt(token) {
            const base64Url = token.split('.')[1];
            const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(c => {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));
            return JSON.parse(jsonPayload);
        },

        navigateToEditOrder() {
            const token = localStorage.getItem('token');             
            const parsedToken = this.parseJwt(token);
            const existingOrderId = parsedToken.orderId;
            this.$router.push({ path: `/edit-order/${existingOrderId}`});
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/global-styles/variables';

.not-available .product {
    position: relative;
    pointer-events: none;
}

.product .not-available {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    color: #fff;
    font-size: 2.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    z-index: 3;
}

.product {
    background-color: $white;
    border-radius: 8px;
    box-shadow: $shadow-1;
    overflow: hidden;
    margin: 1.5rem 0;
    width: 100%;
    
    &-top {
        padding: 1.5rem 3rem;
        
        .title {
            font-size: 2.4rem;
            font-weight: 400;
            margin: 0 0 2rem 0;
            text-align: center;
        }
    }

    &-image {
        margin-bottom: 1.5rem;
        
        img {
            width: 100%;
            max-width: 100%;
            display: block;
        }
    }
    
    &-info {
        padding: 3rem 3rem 3rem 0;
        
        .text-row {
            padding-bottom: 2.5rem;
            font-size: 1.6rem;
            font-weight: 300;
            width: 100%;
        }

        .stamps-row {
            margin: .5rem 0;
            display: flex;
            width: 80%;
            justify-content: space-between;
            align-items: center;
        }

        .stamps {
            width: 100%;
            font-weight: 300;
            font-size: 1.8rem;
            line-height: normal;
            text-transform: none;
        }

        .price {
            text-align: right;
            width: 100%;
        }

        .pricetag {
            background-color: $color-primary;
            border-radius: 100px;
            padding: .6rem 1.8rem;
            color: $white;
            font-size: 1.4rem;
            line-height: normal;
            display: inline-block;
            min-width: 7rem;
            text-align: center;

            &.zero-stamps-price {
                background-color: #ed3b2f;
            }
        }
    }

    .coupon-code {
        margin-bottom: 3rem;

        label {
            margin-bottom: 1rem;
            font-size: 1.6rem;
            color: $grey;
        }

        input[type="text"] {
            font-family: 'Roboto Condensed', 'Helvetica Neue', Arial, Helvetica, sans-serif;
            background-color: $grey-light-7;
            border: 1px solid $grey-light-6;
            border-radius: 0.8rem;
            width: 100%;
            padding: 15px;
        }

        input[type="text"].error {
            background-color: $grey-light-7;
            border: 1px solid red;
        }

        .error {
            color: red;
            margin: 0 0 1rem 0;
            font-size: 1.2rem;
        }
    }

    .pickupdate {
        font-size: 12px;
        margin: 0;
        width: 100%;
        padding: 10px 30px;
        border-top: 1px solid $grey-light-1;
    }

    &-bottom {
        padding: 3rem 3rem 3rem 6rem;
        position: relative;
        
        &::after {
            content: "";
            width: 1px;
            height: calc(100% - 3rem);
            background-color: $grey-light-1;
            position: absolute;
            left: 3rem;
            top: 1.5rem;
        }

        p {
            font-size: 1.6rem;
            color: grey;
            margin-bottom: 1rem;
        }

        .buttons {
            text-align: center;
            margin-top: 30px;
        }
        
        .btn:active {
            transform: scale(1.05);
        }
    }
}

.amount {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    
    &-label {
        font-size: 1.8rem;
        line-height: 3.8rem;
        font-weight: 300;
        margin: 0 1rem;
    }

    &-input {
        margin: 0 1rem;
        
        input[disabled] {
            background: $white !important;
        }

        .inside {
            float: right;
            border: 2px solid $grey-light-3;
            border-radius: 100px;
            overflow: hidden;
            margin-bottom: 0;
        }
    } 
}
    
.input-number {
    width: 40px;
    padding: 0 12px;
    vertical-align: top;
    text-align: center;
    outline: none;
    
    &,
    &-decrement,
    &-increment {
        border: none;
        height: 34px;
        user-select: none;
        font-size: 16px;
        font-weight: 700;
        user-select: none;
    }
    
    &-decrement,
    &-increment {
        display: inline-block;
        width: 30px;
        line-height: 34px;
        background: $white;
        color: $offblack;
        text-align: center;
        font-weight: bold;
        cursor: pointer;
        font-family: Arial, sans-serif;
    }

    &-decrement:active,
    &-increment:active {
        background: $grey-light-3;
    }    
}
    
.badge {
    text-align: center;
    min-width: 35px;
    height: 35px;
    display: inline-block;
    background: $black;
    border-radius: 50%;
    color: $white;
    line-height: 35px;
    margin: 0 5px;
}

@media only screen and (max-width: 850px) {
    .btn.btn-secondary {
        font-size: 1.6rem;
    }
}

@media only screen and (max-width: 800px) {
    .btn.btn-secondary {
        font-size: 1.4rem;
    }
}

@media only screen and (max-width: 768px) {
    .product-info {
        .stamps-row {
            width: 100%;
        }
    }
    .product-bottom::after {
        left: 0;
    }
}

@media only screen and (max-width: 500px) {
    .product-info {
        padding: 3rem;
    }
}
</style>